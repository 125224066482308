<template>
  <div data-test-id="new-room-from" class="v3-form adult-form">
    <div class="row">
      <div class="d-flex flex-column">
        <Label required>Location name</Label>
        <InputField
          :disabled="isIconMode"
          v-model="state.form.name"
          :invalid-feedback="
            errors.name ||
            state.serverErrors.name == 'This room name already exists'
              ? 'This location name already exists'
              : state.serverErrors.name
          "
        ></InputField>
      </div>
      <div class="mt-3">
        <Label>Icon</Label>
        <IconCarousel
          :per-page="10"
          :mouse-drag="true"
          v-model="state.form.icon"
        ></IconCarousel>
      </div>
      <div class="mt-3">
        <Label required>Trip type</Label>
        <CustomSelect
          :disabled="isIconMode"
          :close-on-select="true"
          :options="tripTypeSelect"
          placeholder="Select one"
          :reduce="(option) => option.value"
          v-model="state.form.trip_type"
          :invalid-feedback="errors.trip_type || state.serverErrors.trip_type"
        ></CustomSelect>
      </div>
      <div class="mt-3">
        <Label required>Comment type</Label>
        <CustomSelect
          :disabled="isIconMode"
          :close-on-select="true"
          :options="commentTypeSelect"
          placeholder="Select one"
          :searchable="false"
          :reduce="(option) => option.value"
          v-model="state.form.comment_type"
          :invalid-feedback="
            errors.comment_type || state.serverErrors.comment_type
          "
        ></CustomSelect>
      </div>
      <div class="mt-3 d-flex flex-row justify-content-between">
        <Label>Active</Label>
        <CustomSwitch
          :disabled="isIconMode"
          type="radio"
          :model-value="state.form.status == 1 ? true : false"
          @update:model-value="changeActive"
        ></CustomSwitch>
      </div>
      <div class="mt-3 d-flex flex-row align-items-center">
        <CustomCheck
          data-test-id="room-options-extended-pass-checkbox"
          :disabled="isIconMode"
          v-model="state.form.is_extended"
          :checked="!!state.form.is_extended"
          class="me-2"
        ></CustomCheck>
        <Label class="m-0">Extended pass</Label>
      </div>
      <div v-if="state.form.is_extended" class="mt-3">
        <CustomTimePicker
          data-test-id="room-extended-time-picker"
          :readonly="isIconMode"
          format="HH:mm:ss"
          picker-time-format="HH:mm:ss"
          :show-seconds="true"
          v-model="state.form.extended_pass_time"
          :invalid-feedback="
            errors.extended_pass_time || state.serverErrors.extended_pass_time
          "
        ></CustomTimePicker>
      </div>
      <div>
        <InfoBox
          v-if="state.serverRes"
          class="mt-4"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
      </div>
      <div class="text-center">
        <LoadingButton class="me-2 px-4" @click="onCancel" rounded
          >Cancel</LoadingButton
        >
        <LoadingButton
          data-test-id="save-room-button"
          :is-loading="state.isProcessing"
          @click="submit(isUpdateMode)"
          class="mt-4 px-4"
          solid
          rounded
          >{{ isUpdateMode ? "Update" : "Submit" }}</LoadingButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import Label from "@/v3components/shared/Form/Label"
import InputField from "@/v3components/shared/Form/InputField"
import IconCarousel from "@/v3components/shared/Form/IconCarousel"
import CustomSelect from "@/v3components/shared/Form/CustomSelect"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch"
import CustomCheck from "@/v3components/shared/Form/CustomCheck"
import CustomTimePicker from "@/v3components/shared/Form/CustomTimePicker"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import useVuelidate from "@vuelidate/core"
import { helpers, required, requiredIf } from "@vuelidate/validators"
import { useStore } from "vuex"
import { reactive, inject, computed, onMounted } from "vue"

export default {
  components: {
    Label,
    InputField,
    IconCarousel,
    CustomSelect,
    CustomSwitch,
    CustomCheck,
    CustomTimePicker,
    InfoBox,
    LoadingButton
  },
  props: {
    editLocation: {
      type: Object,
      default: null
    },
    editIconMode: {
      type: Boolean,
      default: false
    }
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const modal = inject("modal")
    const state = reactive({
      isProcessing: false,
      form: {
        name: "",
        icon: "",
        trip_type: "",
        comment_type: "",
        status: "1",
        is_extended: false,
        extended_pass_time: "01:30:00"
      },
      defaultIconSelect: {
        label: "Select from library",
        value: "Select from library"
      },
      defaultActive: true,
      serverErrors: {},
      serverRes: null
    })

    const tripTypeSelect = [
      { label: "Layover", value: "Layover" },
      { label: "One Way", value: "One Way" },
      { label: "Roundtrip", value: "Roundtrip" }
    ]

    const commentTypeSelect = [
      { label: "Mandatory", value: "Mandatory" },
      { label: "Optional", value: "Optional" },
      { label: "Hidden", value: "Hidden" }
    ]

    const isUpdateMode = computed(() => Boolean(props.editLocation))

    const isIconMode = computed(() => Boolean(props.editIconMode))

    onMounted(() => {
      if (props.editLocation) {
        setFormData({
          name: props.editLocation.name,
          icon: props.editLocation.icon,
          trip_type: props.editLocation.trip_type,
          comment_type: props.editLocation.comment_type,
          status: props.editLocation.status,
          is_extended: !!props.editLocation.nurses,
          extended_pass_time: props.editLocation.nurses
            ? props.editLocation.nurses.end_time
            : "01:30:00"
        })
      }
    })

    const setFormData = (data) => {
      state.form = Object.assign(state.form, data)
    }

    const changeActive = (isActive) => {
      state.defaultActive = isActive
      state.form.status = isActive ? "1" : "0"
    }

    const validationMessages = {
      required: "This field is required"
    }
    const validations = {
      form: {
        name: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        trip_type: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        comment_type: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        status: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        extended_pass_time: {
          required: requiredIf(() => {
            return state.form.is_extended
          })
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const submit = () => {
      if (isFormValid.value) {
        if (!state.form.is_extended) {
          state.form.extended_pass_time = null
        }
        if (isUpdateMode.value) {
          const data = {
            roomId: props.editLocation.id,
            data: state.form
          }
          updateRoom(data)
        } else {
          createRoom()
        }
      } else {
        v$.value.$touch()
      }
    }

    const createRoom = () => {
      state.isProcessing = true
      store
        .dispatch("rooms/createRoom", state.form)
        .then(() => {
          resetFormData()
          setResponseInfoBox("Success", "Successfully created!")
          state.isProcessing = false
          state.serverErrors = {}
          modal.setAction("createRoom")
          setTimeout(() => {
            setResponseInfoBox()
            emit("cancel")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const updateRoom = (data) => {
      state.isProcessing = true
      store
        .dispatch("rooms/updateRoom", data)
        .then((res) => {
          const data = res.data
          resetFormData()
          setResponseInfoBox("Success", "Successfully updated!")
          state.isProcessing = false
          state.serverErrors = {}
          store.commit("rooms/UPDATE_ROOM", data?.data)
          modal.setAction("updateRoom")
          setTimeout(() => {
            setResponseInfoBox()
            emit("cancel")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onCancel = () => {
      resetFormData()
      emit("cancel")
    }

    const resetFormData = () => {
      state.form = {
        name: "",
        trip_type: "",
        comment_type: "",
        status: "1",
        extended_pass_time: "01:30:00"
      }
    }

    return {
      state,
      tripTypeSelect,
      commentTypeSelect,
      isUpdateMode,
      isIconMode,
      changeActive,
      v$,
      isFormValid,
      errors,
      submit,
      setResponseInfoBox,
      onCancel,
      resetFormData
    }
  }
}
</script>
